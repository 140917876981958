import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"

import CONFIG from "../config"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Game, useGames } from "../subscriptions/games"
import { GameStatus } from "../lib/gameStatus"
import { pluralize, queryParams } from "../lib/helpers"
import { userSelector, UserState } from "../hasura/slices/user"

export default function WaitingGame() {
  const { user }: UserState = useSelector(userSelector)
  const [games, setGames] = useState<Game[]>([])

  // @ts-ignore
  const game = games.find(({ id }) => id === queryParams()?.id)

  useGames((games) => {
    setGames(games)
  }, user?.classroom?.teacher_id)

  useEffect(() => {
    if (game?.status === GameStatus.InProgress) {
      navigate(`/play?type=multiplayer&id=${game.id}&r=${game.root?.id}${CONFIG.IS_DEVELOPMENT ? "&test=true" : ""}`)
    }
  }, [game])

  return (
    <Layout>
      <SEO title="Waiting Game" />

      {game && (
        <div
          style={{ height: `calc(100vh - ${CONFIG.HEADER_HEIGHT}px)` }}
          className="w-100 d-flex align-items-center justify-content-center flex-column"
        >
          <p className="text-xxxl">{pluralize("minute", game?.minutes)} game</p>

          <p className="mb-1 text-m gray8 font-italic">Get ready!</p>

          <p className="m-0 text-xxs semibold">{pluralize("player", game?.positions.length)} in lobby</p>
        </div>
      )}
    </Layout>
  )
}
